import React from "react";
import eldercare from "../assets/eldercare.jpg";
import building from "../assets/building.jpg";

// Replace with placeholder if needed:
// const eldercare = "/api/placeholder/400/320";
// const building = "/api/placeholder/400/320";

const Solutions: React.FC = () => {
  const solutions = [
    {
      image: eldercare,
      title: "Smart Eldercare",
      points: [
        "In-bed detection and monitoring",
        "Sleep tracking and quality assessment",
        "Bathroom visit analysis",
        "Fall detection in critical areas",
      ],
    },
    {
      image: building,
      title: "Intelligent Building",
      points: [
        "Real-time space utilization tracking",
        "Historical usage trend analysis",
        "Space optimization recommendations",
        "Integration of Sensing AI, Behavioral AI, and Spatial AI",
      ],
    },
  ];

  return (
    <section className="solutions" id="solutions">
      <h2>Our Solutions</h2>
      <div className="solution-grid">
        {solutions.map((solution, index) => (
          <div
            className="solution-card"
            key={index}
            data-title={solution.title}
          >
            <div className="solution-image-wrapper">
              <img
                src={solution.image}
                alt={solution.title}
                className="solution-image"
              />
            </div>
            <h3>{solution.title}</h3>
            <ul>
              {solution.points.map((point, pointIndex) => (
                <li key={pointIndex}>{point}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Solutions;
